<app-header  class="sticky-top"></app-header>

<app-menu-sidebar class="bg-white layout-fixed" style="position: fixed !important;overflow-x: hidden;"></app-menu-sidebar>

<div class="content-wrapper">
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
<app-control-sidebar></app-control-sidebar>
<div (click)="onToggleMenuSidebar()" id="sidebar-overlay"></div>
