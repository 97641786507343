<!--<div class="float-right d-none d-sm-block"><b>Version</b> {{ appVersion }}</div>-->
<strong>
  <span>Copyright &copy; {{ currentYear }}</span>
  <a
    href="https://varnikcloud.com" class="text-black"
    rel="noopener noreferrer"
    style="margin: 0"
    target="_blank"
  > VarNik Cloud</a
  >
  <span>.</span>
</strong>
<span> All rights reserved.</span>
