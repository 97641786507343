import {Component, Renderer2} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Subject, takeUntil} from 'rxjs';
import {BlogDetails} from "@/modals/blogs/BlogDetails";
import Swal from "sweetalert2";

@Component({
    selector: 'app-blogs',
    templateUrl: './blogs.component.html',
    styleUrls: ['blogs.component.scss']
})

export class BlogsComponent {
    isModalVisible = false;
    updateButtonShow = false;
    destroy$: Subject<boolean> = new Subject<boolean>();
    searchText;
    blogDetails: BlogDetails | undefined;
    allBlogList: BlogDetails [] = [];
    fileList: File[] = [];
    isCardCollapsed: boolean[] = [];
    imageUrl: string;
    selectBlogPosition: number;
    onlyEditShow = false;
    isButtonGroupClicked = false;
    isAdmin: string;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService
    ) {
    }

    addBlog = new UntypedFormGroup({
        blogPostTitle: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        blogPostDescription: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        websiteId: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        websiteName: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        blogPostSeq: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        blogPostImagePath: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        imageName: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
    })

    onSubmit() {
        this.blogFormValidation();
    }

    handleImageError() {
        /*this.imageUrl = "src/assets/img/dummy-image.png";*/
    }

    public blogFormErrors = {
        blogPostTitle: "",
        blogPostDescription: ""
    }

    blogFormValidation() {
        this.blogFormErrors.blogPostTitle = ""
        this.blogFormErrors.blogPostDescription = ""

        let hasError = false;

        if (this.addBlog.get("blogPostTitle")?.invalid) {
            this.blogFormErrors.blogPostTitle = "Blog Title is Required";
            hasError = true;
        }
        if (this.addBlog.get("blogPostDescription")?.invalid) {
            this.blogFormErrors.blogPostDescription = "Blog Description is Required";
            hasError = true;
        }
        if (!hasError) {
            this.saveBlogDetails();
        }
    }

    ngOnInit() {
        this.isAdmin = localStorage.getItem('accessType');
        this.allBlogDetails();
    }

    openModal() {
        this.isModalVisible = !this.isModalVisible;
        this.updateButtonShow = false;
    }

    closeModal() {
        this.isModalVisible = false;
        this.addBlog.reset();
    }

    toggleCard(index: number) {
        this.isCardCollapsed[index] = !this.isCardCollapsed[index];
    }

    openEditModal(blogTitle: string, blogDesc: string, blogSeq: number, i: number) {
        this.updateButtonShow = true;
        this.onlyEditShow = true;
        this.selectBlogPosition = i;
        this.isModalVisible = !this.isModalVisible;
        this.isCardCollapsed[i] = !this.isButtonGroupClicked;
        this.addBlog.get('blogPostTitle').setValue(blogTitle);
        this.addBlog.get('blogPostSeq').setValue(blogSeq);
        this.addBlog.get('blogPostDescription').setValue(blogDesc);
        const imagePath = this.allBlogList[i].blogPostImagePath;
        this.addBlog.controls['blogPostImagePath'].setValue(imagePath);
        const fileName = this.getFileNameFromPath(imagePath);
        this.addBlog.controls['imageName'].setValue(fileName);
    }

    getFileNameFromPath(path: string): string {
        const parts = path.split('/');
        return parts[parts.length - 1];
    }

    allBlogDetails() {
        this.spinner.show();
        this.appService
            .getAllBlogDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                    this.allBlogList = data;
                    this.allBlogList.sort((a, b) => b.blogPostSeq - a.blogPostSeq);
                    if (this.allBlogList.length == 0) {
                        this.toastr.info("No blog found")
                    }
                    this.spinner.hide();
                    console.log("Blogs List::", this.allBlogList)
                },
                (error: any) => {
                    console.log("error:", error);
                    this.spinner.hide();
                    if (error && error.error && error.error.msg) {
                        this.spinner.hide();
                        this.toastr.error(error.error.msg);
                    } else {
                        this.spinner.hide();
                        this.toastr.error("Something went wrong, please try again");
                    }
                });
    }

    onFileSelected(event: any) {
        this.fileList = [];
        for (var i = 0; i <= event.target.files.length - 1; i++) {
            var selectedFile = event.target.files[i];
            this.onlyEditShow = false;
            console.log("select file size ::" + selectedFile);
            var fileSizeInMb = Math.round((selectedFile.size * 100) / (1024 * 1024)) / 100;
            console.log("select file convert size ::" + fileSizeInMb);
            if (fileSizeInMb <= 3) {
                this.fileList.push(selectedFile);
                console.log("File list::", this.fileList)
            } else {
                this.toastr.error("File size is large. Please upload below 3MB")
            }
        }
    }

    saveBlogDetails() {
        const selectedWebsiteId = Number(localStorage.getItem('selectedWebsiteId'));
        const selectedWebsiteName = localStorage.getItem('selectedWebsiteName');

        this.addBlog.controls['websiteId'].setValue(selectedWebsiteId);
        this.addBlog.controls['websiteName'].setValue(selectedWebsiteName);

        const isUpdate = this.updateButtonShow;
        const actionVerb = isUpdate ? 'Update' : 'Add';

        this.spinner.show();
        const request = isUpdate
            ? this.appService.updateBlogDetails(this.addBlog.value)
            : this.appService.addBlogDetails(this.addBlog.value);

        request.pipe(takeUntil(this.destroy$)).subscribe(
            (data: any) => {
                console.log(`${actionVerb} Blog details::`, data);
                const formData = new FormData();
                for (const img of this.fileList) {
                    formData.append('file', img, img.name);
                }

                this.blogDetails = data;
                if (this.fileList.length > 0) {
                    this.saveBlogImageDetails(formData, this.blogDetails.blogPostSeq);
                }

                this.spinner.hide();
                this.toastr.success(`Blog ${actionVerb.toLowerCase()}ed successfully`);

                if (isUpdate) {
                    const updateBlogData = this.allBlogList[this.selectBlogPosition];
                    updateBlogData.blogPostTitle = data.blogPostTitle;
                    updateBlogData.blogPostDescription = data.blogPostDescription;
                    this.selectBlogPosition = null;
                    this.closeModal();
                } else {
                    this.allBlogList.push(data);
                    this.addBlog.reset();
                }
            },
            (error: any) => {
                console.log("error:", error);
                this.spinner.hide();

                if (error && error.error && error.error.msg) {
                    this.toastr.error(error.error.msg);
                } else {
                    this.toastr.error("Something went wrong, please try again");
                }
            }
        );
    }


    saveBlogImageDetails(formData: FormData, blogPostId: number) {
        this.spinner.show();
        this.appService
            .addBlogImage(formData, blogPostId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                    console.log("Image::", data);
                    this.spinner.hide();
                    location.reload();
                    this.closeModal();
                    this.fileList = [];
                },
                (error: any) => {
                    console.log("error:", error);
                    this.spinner.hide();
                    if (error && error.error && error.error.msg) {
                        this.toastr.error(error.error.msg);
                    } else {
                        this.toastr.error("Something went wrong, please try again");
                    }
                })
    }

    deleteBlogDetails(blogSeq: number, blogPosition: number) {
        this.isCardCollapsed[blogPosition] = !this.isButtonGroupClicked;
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                this.appService
                    .deleteBlog(blogSeq)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(data => {
                            console.log("deleted Blog::", data);
                            location.reload();
                            this.toastr.success("Blog deleted successfully");
                            this.allBlogList.splice(blogPosition, 1);
                            Swal.fire({
                                title: "Deleted",
                                text: "Your blog has been deleted.",
                                icon: "success"
                            });
                        },
                        (error: any) => {
                            console.log("error:", error);
                            this.spinner.hide();
                            if (error && error.error && error.error.msg) {
                                this.toastr.error(error.error.msg);
                            }
                        });
            }
        }, (error: any) => {
            console.log("error:", error);
            this.spinner.hide();
            if (error && error.error && error.error.msg) {
                this.toastr.error(error.error.msg);
            } else {
                this.toastr.error("Something went wrong, please try again");
            }
        });
    }

    openStatus(blogSeq: number, showStatus: string, event, index) {
        this.isCardCollapsed[index] = !this.isCardCollapsed[index];
        const isChecked = event.target.checked;
        var confirmButtonText: any;
        if (isChecked) {
            showStatus = 'Showing';
            confirmButtonText = 'Show'
        } else {
            showStatus = 'Not Showing';
            confirmButtonText = 'Hide'
        }

        console.log("checked::", isChecked);

        Swal.fire({
            title: "Are you sure?",
            text: "Are you want to show or hide your blog post",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: confirmButtonText
        }).then((result) => {
            if (result.isConfirmed) {
                // The user clicked on "Okay", update the Blog Poststatus
                this.appService
                    .updateBlogStatus(blogSeq, showStatus)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(data => {
                            console.log("Update Blog Post Status::", data);
                            this.blogDetails = data;
                            this.toastr.success("Blog Post Status Updated successfully");
                            /* const updatedNewsFeed = this.allNewsFeedList[this.selectNewsFeedPosition];
                             updatedNewsFeed.status = data.status;*/
                            Swal.fire({
                                title: "Update",
                                text: "Your Blog Post status has been updated.",
                                icon: "success"
                            });
                        },
                        (error: any) => {
                            console.log("error:", error);
                            this.spinner.hide();
                            if (error && error.error && error.error.msg) {
                                this.toastr.error(error.error.msg);
                            }
                        });
            } else {
                event.target.checked = !isChecked;
                console.log("Switch state after denied:", event.target.checked);
                this.toastr.info("Action canceled");
            }
        }, (error: any) => {
            console.log("error:", error);
            this.spinner.hide();
            if (error && error.error && error.error.msg) {
                this.toastr.error(error.error.msg);
            } else {
                this.toastr.error("Something went wrong, please try again");
            }
        });
    }

    /*saveBlogDetails() {
        if (this.updateButtonShow == false) {
            this.addBlog.controls['websiteId'].setValue(Number(localStorage.getItem('selectedWebsiteId')));
            this.addBlog.controls['websiteName'].setValue(localStorage.getItem('selectedWebsiteName'));
            this.spinner.show();
            this.appService
                .addBlogDetails(this.addBlog.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                        console.log("Blog details::", data);
                        const formData = new FormData();
                        for (let img of this.fileList) {
                            formData.append('file', img, img.name);
                        }
                        this.blogDetails = data;
                        if (this.fileList.length > 0) {
                            this.saveBlogImageDetails(formData, this.blogDetails.blogPostSeq);
                        }
                        this.spinner.hide();
                        this.toastr.success("Blog added successfully");
                        this.addBlog.reset();
                        this.allBlogList.push(data);
                    },
                    (error: any) => {
                        console.log("error:", error);
                        this.spinner.hide();
                        if (error && error.error && error.error.msg) {
                            this.toastr.error(error.error.msg);
                            this.spinner.hide();
                        } else {
                            this.spinner.hide();
                            this.toastr.error("Something went wrong, please try again");
                        }
                    }
                );
        }

        if (this.updateButtonShow == true) {
            this.addBlog.controls['websiteId'].setValue(Number(localStorage.getItem('selectedWebsiteId')));
            this.addBlog.controls['websiteName'].setValue(localStorage.getItem('selectedWebsiteName'));
            this.spinner.show();
            this.appService
                .updateBlogDetails(this.addBlog.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                        console.log("Blog details::", data);
                        const formData = new FormData();
                        for (let img of this.fileList) {
                            formData.append('file', img, img.name);
                        }
                        this.blogDetails = data;
                        if (this.fileList.length > 0) {
                            this.saveBlogImageDetails(formData, this.blogDetails.blogPostSeq);
                        }
                        this.spinner.hide();
                        this.toastr.success("Blog Updated successfully");
                        this.allBlogList[this.selectBlogPosition].blogPostTitle = data.blogPostTitle;
                        this.allBlogList[this.selectBlogPosition].blogPostDescription = data.blogPostDescription;
                        this.selectBlogPosition = null;
                        this.closeModal();
                    },
                    (error: any) => {
                        console.log("error:", error);
                        this.spinner.hide();
                        if (error && error.error && error.error.msg) {
                            this.toastr.error(error.error.msg);
                            this.spinner.hide();
                        } else {
                            this.spinner.hide();
                            this.toastr.error("Something went wrong, please try again");
                        }
                    }
                );
        }
    }*/
}