import {Component} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class BlankComponent {

  activeTab: string = 'tab_1';

  changeTab(tabId: string) {
    this.activeTab = tabId;
  }
}
