import {Component, OnInit, Renderer2, ViewEncapsulation} from "@angular/core";
import {AppService} from "@services/app.service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {Subject, takeUntil} from "rxjs";
import {WebsiteDto} from "@/modals/website/WebsiteDto";
import {ToggleSidebarMenu} from "@/store/ui/actions";
import {Store} from "@ngrx/store";
import {AppState} from "@/store/state";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class HomeComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    websiteList: WebsiteDto [] = [];
    private store: Store<AppState>
    userName: string;

    constructor(private renderer: Renderer2, private toastr: ToastrService, private appService: AppService,
                private router: Router, private spinner: NgxSpinnerService) {
    }

    ngOnInit() {
        this.getMyWebsiteList();
        this.userName = localStorage.getItem('userName');
    }

    getMyWebsiteList() {
        this.spinner.show();
        this.appService
            .getWebsiteList()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.spinner.hide();
                console.log("website list::", data);
                this.websiteList = data;
            }, (err: any) => {
                this.spinner.hide();
                this.toastr.error("Something went wrong");
                console.log(err);
            });
    }

    openSiteBasedDetails(website: any) {
        localStorage.setItem('selectedWebsiteId', website.websiteId.toString());
        localStorage.setItem('selectedWebsiteName', website.websiteName);
        localStorage.setItem('accessType', website.accessType);
        this.router.navigate(['/main/gallery']);
    }

    onToggleMenuSidebar() {
        this.store.dispatch(new ToggleSidebarMenu());
    }
}