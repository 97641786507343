import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Gatekeeper} from 'gatekeeper-client-sdk';
import {LoginRequest} from "@services/loginRequest";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {LoginResponse} from "@services/loginResponse";
import {LocalDataService} from "@services/localDataService";
import {AlbumDetails} from "@/modals/album/albumDetails";
import {UntypedFormGroup} from '@angular/forms';
import {GalleryDetails} from "@/modals/gallery/galleryDetails";
import {AlbumImageDetails} from "@/modals/album/albumImageDetails";
import {NewsFeedDetails} from "@/modals/news-feed/NewsFeedDetails";
import {WebsiteDto} from "@/modals/website/WebsiteDto";
import {BlogDetails} from "@/modals/blogs/BlogDetails";
import {PressRelease} from "@/modals/press-release/PressRelease";
import {Observable} from "rxjs";
import {Client} from "@/modals/client/Client";

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;

    constructor(private router: Router, private toastr: ToastrService, private  http: HttpClient, private localData: LocalDataService) {
    }

    endPoint = "https://tomcat3.varnik.cloud:8443/varnik-cms-ws";

    /*async loginByAuth({email, password}) {
      try {
        const token = await Gatekeeper.loginByAuth(email, password);
        localStorage.setItem('token', token);
        await this.getProfile();
        this.router.navigate(['/']);
        this.toastr.success('Login success');
      } catch (error) {
        this.toastr.error(error.message);
      }
    }*/

    async registerByAuth({email, password}) {
        try {
            const token = await Gatekeeper.registerByAuth(email, password);
            localStorage.setItem('token', token);
            /* await this.getProfile();*/
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByGoogle() {
        try {
            const token = await Gatekeeper.loginByGoogle();
            localStorage.setItem('token', token);
            /* await this.getProfile();*/
            this.router.navigate(['/']);
            this.toastr.success('Login success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByGoogle() {
        try {
            const token = await Gatekeeper.registerByGoogle();
            localStorage.setItem('token', token);
            /* await this.getProfile();*/
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByFacebook() {
        try {
            const token = await Gatekeeper.loginByFacebook();
            localStorage.setItem('token', token);
            /* await this.getProfile();*/
            this.router.navigate(['/']);
            this.toastr.success('Login success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByFacebook() {
        try {
            const token = await Gatekeeper.registerByFacebook();
            localStorage.setItem('token', token);
            /*await this.getProfile();*/
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    /*async getProfile() {
      try {
        this.user = await Gatekeeper.getProfile();
      } catch (error) {
        this.logout();
        throw error;
      }
    }*/

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('gatekeeper_token');
        this.user = null;
        this.router.navigate(['/login']);
    }


    //Check Login Request
    login(loginRequest: LoginRequest) {
        return this.http.post<LoginResponse>(
            this.endPoint + "/auth/login", loginRequest
        )
    }

    //save Gallery Details
    saveGallery(websiteId: number) {
        return this.http.post<any>(
            this.endPoint + "/api/admin-dashboard/gallery/save/" + websiteId, null,
            {headers: this.localData.getHeaders()}
        )
    }

    //Get all gallery Image Details
    getAllGallery() {
        return this.http.get<GalleryDetails>(
            this.endPoint + "/api/front-end/gallery/getAll",
            {headers: this.localData.getHeaders()}
        )
    }

    //get Albums Details
    getAlbums() {
        return this.http.get<AlbumDetails[]>(
            this.endPoint + "/api/front-end/album/getAll",
            {headers: this.localData.getHeaders()}
        )
    }

    //save New Album Details
    addNewAlbum(userForm: UntypedFormGroup) {
        return this.http.post<AlbumDetails>(
            this.endPoint + "/api/admin-dashboard/album/save", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //Update Album Details
    updateAlbum(userForm: UntypedFormGroup) {
        return this.http.post<AlbumDetails>(
            this.endPoint + "/api/admin-dashboard/album/update", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //Delete Album Details
    deleteAlbum(albumId: number) {
        return this.http.delete<any>(
            this.endPoint + "/api/admin-dashboard/album/delete/" + albumId,
            {headers: this.localData.getHeaders()}
        )
    }

    //Delete Album Picture
    deleteAlbumPicture(pictureId: number) {
        return this.http.delete<any>(
            this.endPoint + "/api/admin-dashboard/gallery/deleteImage/" + pictureId,
            {headers: this.localData.getHeaders()}
        )
    }

    //Add Album Images
    addAlbumImages(formData: FormData, albumId: number,) {
        return this.http.post<any>(
            this.endPoint + "/api/admin-dashboard/gallery/saveToAlbum/" + albumId, formData,
            {headers: this.localData.getImageHeaders()}
        )
    }

    //Get all website Details
    getWebsiteList() {
        return this.http.get<WebsiteDto[]>(
            this.endPoint + "/api/admin-dashboard/getMySiteList",
            {headers: this.localData.getSiteHeaders()}
        )
    }


    //Add Blog Details
    addBlogDetails(userForm: UntypedFormGroup) {
        return this.http.post<BlogDetails>(
            this.endPoint + "/api/admin-dashboard/blogPost/add", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //Update Blog Details
    updateBlogDetails(userForm: UntypedFormGroup) {
        return this.http.put<BlogDetails>(
            this.endPoint + "/api/admin-dashboard/blogPost/update", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //update Blog status
    updateBlogStatus(blogSeq: number, showStatus: string) {
        return this.http.put<BlogDetails>(
            this.endPoint + "/api/admin-dashboard/blogPost/update/" + blogSeq + "/" + showStatus, '',
            {headers: this.localData.getHeaders()}
        )
    }

    //Delete Album Details
    deleteBlog(blogSeq: number) {
        return this.http.delete<any>(
            this.endPoint + "/api/admin-dashboard/blogPost/deleteById/" + blogSeq,
            {headers: this.localData.getHeaders()}
        )
    }

    //Add Blog Image
    addBlogImage(formData: FormData, blogPostSeqId: number,) {
        return this.http.post<any>(
            this.endPoint + "/api/admin-dashboard/blogPost/picture/upload?blogPostId=" + blogPostSeqId, formData,
            {headers: this.localData.getImageHeaders()}
        )
    }

    //Get all Blog Details
    getAllBlogDetails() {
        return this.http.get<BlogDetails[]>(
            this.endPoint + "/api/front-end/blogPost/getAll",
            {headers: this.localData.getHeaders()}
        )
    }

    //add News Feed Details
    addNewsFeedDetails(userForm: UntypedFormGroup) {
        return this.http.post<NewsFeedDetails>(
            this.endPoint + "/api/admin-dashboard/news_feed/add", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //Add News Feed Image
    addNewsFeedImage(formData: FormData, newsFeedId: number,) {
        return this.http.post<any>(
            this.endPoint + "/api/admin-dashboard/news_feed/picture/upload?productId=" + newsFeedId, formData,
            {headers: this.localData.getImageHeaders()}
        )
    }

    //Get all News Feed Details
    getAllNewsFeedDetails(websiteName: string) {
        return this.http.get<NewsFeedDetails[]>(
            this.endPoint + "/api/admin-dashboard/newsfeed/get?website_name=" + websiteName,
            {headers: this.localData.getHeaders()}
        )
    }

    //Update News Feed Details
    updateNewsFeedDetails(userForm: UntypedFormGroup) {
        return this.http.put<NewsFeedDetails>(
            this.endPoint + "/api/admin-dashboard/news_feed/update", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //update news feed status
    updateNewsFeedStatus(newsFeedId: number, showStatus: string) {
        return this.http.put<NewsFeedDetails>(
            this.endPoint + "/api/admin-dashboard/news_feed/update/" + newsFeedId + "/" + showStatus, '',
            {headers: this.localData.getHeaders()}
        )
    }

    //update news feed status
    updateNewsFeedImageStatus(newsFeedId: number, showImageStatus: string) {
        return this.http.put<NewsFeedDetails>(
            this.endPoint + "/api/admin-dashboard/news_feed/image_update/" + newsFeedId + "/" + showImageStatus, '',
            {headers: this.localData.getHeaders()}
        )
    }

    //Delete Album Details
    deleteNewsFeed(newsFeedId: number) {
        return this.http.delete<any>(
            this.endPoint + "/api/admin-dashboard/news_feed/deleteById/" + newsFeedId,
            {headers: this.localData.getHeaders()}
        )
    }

    //Get all News Feed Details
    getAllPressRelease() {
        return this.http.get<PressRelease[]>(
            this.endPoint + "/api/admin-dashboard/press-details/getAll",
            {headers: this.localData.getHeaders()}
        )
    }

    //Save Press Release Details
    savePressRelease(userForm: UntypedFormGroup) {
        return this.http.post<PressRelease>(
            this.endPoint + "/api/admin-dashboard/press-details/add", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //Save Press PDF File
    addPressPDFFile(formData: FormData, pressId: number,) {
        return this.http.post<any>(
            this.endPoint + "/api/admin-dashboard/press-details/file/upload?press_id=" + pressId, formData,
            {headers: this.localData.getImageHeaders()}
        )
    }

    //Get PDF File By Press ID
    getPDFByPressId(pressId: number): Observable<Blob> {
        return this.http.get(
            this.endPoint + "/api/front-end/press-details/getFile/" + pressId,
            {
                headers: this.localData.getHeadersWithoutToken(),
                responseType: 'blob'
            }
        )
    }

    //Update Press Release Details
    updatePressRelease(userForm: UntypedFormGroup) {
        return this.http.put<PressRelease>(
            this.endPoint + "/api/admin-dashboard/press-details/update", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //update Press Release Details status
    updatePressReleaseStatus(pressId: number, showStatus: string) {
        return this.http.put<PressRelease>(
            this.endPoint + "/api/admin-dashboard/press-details/update/" + pressId + "/" + showStatus, '',
            {headers: this.localData.getHeaders()}
        )
    }

    //Delete Press Release Details
    deletePressRelease(pressId: number) {
        return this.http.delete<any>(
            this.endPoint + "/api/admin-dashboard/press-details/deleteById/" + pressId,
            {headers: this.localData.getHeaders()}
        )
    }

    //Save Client Details
    saveClientDetails(userForm: UntypedFormGroup) {
        return this.http.post<Client>(
            this.endPoint + "/api/admin-dashboard/companyPartner/add", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //Save Client Image
    addClientImage(formData: FormData, clientId: number,) {
        return this.http.post<any>(
            this.endPoint + "/api/admin-dashboard/companyPartner/picture/upload?id=" + clientId, formData,
            {headers: this.localData.getImageHeaders()}
        )
    }

    //Update Client Details
    updateClientDetails(userForm: UntypedFormGroup) {
        return this.http.put<Client>(
            this.endPoint + "/api/admin-dashboard/companyPartner/update", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //update Client Details status
    updateClientDetailStatus(clientId: number, showStatus: string) {
        return this.http.put<Client>(
            this.endPoint + "/api/admin-dashboard/companyPartner/update/" + clientId + "/" + showStatus, '',
            {headers: this.localData.getHeaders()}
        )
    }


    //Get all client details
    getAllClientDetails() {
        return this.http.get<Client[]>(
            this.endPoint + "/api/front-end/companyPartner/getAll",
            {headers: this.localData.getHeadersWithoutToken()}
        )
    }

    //Delete Client Details
    deleteClientDetails(clientId: number) {
        return this.http.delete<any>(
            this.endPoint + "/api/admin-dashboard/companyPartner/deleteById/" + clientId,
            {headers: this.localData.getHeaders()}
        )
    }
}
