import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Observable} from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
  @HostBinding('class') classes: string = BASE_CLASSES;
  public ui: Observable<UiState>;
  public user;
  public menu = MENU;
  getUserName: string;

  constructor(
    public appService: AppService,
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.ui = this.store.select('ui');
    this.ui.subscribe((state: UiState) => {
      this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
    });
    this.user = this.appService.user;

    this.getUserName = localStorage.getItem('userName');
    if (this.getUserName == 'aftc' || this.getUserName == 'Chamberincharge') {
      this.menu = AFTCMenu
    }
  }
}

export const MENU = [
  /*{
    name: 'Home',
    iconClasses: 'fas fa-home',
    path: ['/main/home']
  },*/
  {
    name: 'Gallery',
    iconClasses: 'fas fa-images',
    path: ['/main/gallery']
  },
  {
    name: 'Blogs',
    iconClasses: 'fas fa-blog',
    path: ['/main/blogs']
  },
  {
    name: 'News Feed',
    iconClasses: 'fas fa-newspaper',
    path: ['/main/news-feed']
  },
  {
    name: 'Press Release',
    iconClasses: 'far fa-newspaper',
    path: ['/main/press-release']
  },
  {
    name: 'Clients',
    iconClasses: 'fas fa-handshake',
    path: ['/main/company-partners']
  }
  /*{
    name: 'Blank',
    iconClasses: 'fas fa-file',
    path: ['/main/blank']
  },
  {
    name: 'Main Menu',
    iconClasses: 'fas fa-folder',
    children: [
      {
        name: 'Sub Menu',
        iconClasses: 'far fa-address-book',
        path: ['/main/sub-menu-1']
      },
      {
        name: 'Blank',
        iconClasses: 'fas fa-file',
        path: ['/main/sub-menu-2']
      }
    ]
  }*/
];

export const AFTCMenu = [
  /*{
    name: 'Home',
    iconClasses: 'fas fa-home',
    path: ['/main/home']
  },*/
  {
    name: 'Gallery',
    iconClasses: 'fas fa-images',
    path: ['/main/gallery']
  },
 /* {
    name: 'Blogs',
    iconClasses: 'fas fa-blog',
    path: ['/main/blogs']
  },*/
  {
    name: 'News Feed',
    iconClasses: 'fas fa-newspaper',
    path: ['/main/news-feed']
  },
  {
    name: 'Press Release',
    iconClasses: 'far fa-newspaper',
    path: ['/main/press-release']
  },
  /*{
    name: 'Clients',
    iconClasses: 'fas fa-handshake',
    path: ['/main/company-partners']
  }*/
  /*{
    name: 'Blank',
    iconClasses: 'fas fa-file',
    path: ['/main/blank']
  },
  {
    name: 'Main Menu',
    iconClasses: 'fas fa-folder',
    children: [
      {
        name: 'Sub Menu',
        iconClasses: 'far fa-address-book',
        path: ['/main/sub-menu-1']
      },
      {
        name: 'Blank',
        iconClasses: 'fas fa-file',
        path: ['/main/sub-menu-2']
      }
    ]
  }*/
];
