<section class="content">
    <div class="container-fluid">
        <div class="row mt-2">
            <div class="col-lg-6 col-md-6 col-12 mt-md-2 mb-3">
                <h5 class="m-0 ml-3 mt-md-2 title-album">Clients <span class="h4" *ngIf="isAdmin == 'ALL'"><img src="assets/img/icons/plus.png"
                                                                                       class="img-fluid" type="button"
                                                                                       (click)="openModal()"></span>
                </h5>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="form-floating float-lg-right">
                    <input type="search" class="form-control" placeholder="search" [(ngModel)]="searchText">
                    <label class="text-gray">Search</label>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="card direct-chat direct-chat-primary card-light-blue"
                 *ngFor="let clients of this.allClientDetails |filter: searchText; let i= index;">
                <div class="card-header ui-sortable-handle border-0" type="button" style="cursor: move;"
                     (click)="toggleCard(i)">
                    <h3 class="card-title mt-2">{{clients.companyPartnerName}}</h3>
                    <div class="btn-group ml-2">
                        <button type="button" class="btn btn-md" *ngIf="isAdmin == 'ALL' ">
                            <img src="assets/img/icons/pencil.png" class="img-fluid" title="Click to edit client details"
                                 (click)="openEditModal(clients.companyPartnerDetailsSeq,clients.companyPartnerName, clients.companyPartnerLink, i)">
                        </button>
                        <button type="button" class="btn btn-md text-gradient-blue" *ngIf="isAdmin == 'ALL'">
                            <img src="assets/img/icons/bin.png" class="img-fluid" title="Click to delete client details" (click)="deleteClientDetails(clients.companyPartnerDetailsSeq, i)">
                        </button>
                    </div>
                    <div class="card-tools">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" [checked]="clients.status === 'Showing'" (change)="openStatus(clients.companyPartnerDetailsSeq, clients.status, $event,i)">
                        </div>
                        <!--<button type="button" class="btn text-gradient-blue">
                            <i class="fas"
                               [ngClass]="{'fa-minus': !isCardCollapsed[i], 'fa-plus': isCardCollapsed[i]}"></i>
                        </button>-->
                    </div>
                </div>

                <div class="card-body" [style.display]="isCardCollapsed[i] ? 'none' : 'block'">
                    <div class="direct-chat-messages">
                        <div class="container-fluid col-lg-11">
                            <div class="row mt-3">
                                <div class="col-lg-9 col-12">
                                    <div class="row mt-2">
                                        <h6>Client website : <span>{{clients.companyPartnerLink}}</span></h6>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-12">
                                    <div class="card" style="height: 150px;width: 150px;">
                                        <img src="https://tomcat3.varnik.cloud:8443/varnik-cms-ws/api/front-end/companyPartner/getImage/{{clients.companyPartnerDetailsSeq}}"
                                             class="img-fluid" (error)="handleImageError();" loading="lazy">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" style="background-color: rgba(54,54,54,0.47)" id="exampleModal" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalLabel" aria-hidden="true" [style.display]="isModalVisible ? 'block' : 'none'">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Clients details</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                (click)="closeModal();">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="clients">
                            <div class="row mb-3">
                                <div class="col-lg-12">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="companyPartnerName"
                                               placeholder="Enter Client Company Name">
                                        <label class="fw-bolder text-gray">Client Company Name</label>
                                    </div>
                                </div>
                                <span class="text-danger"
                                      *ngIf="clientErrors.companyPartnerName">{{clientErrors.companyPartnerName}}</span>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-12">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="companyPartnerLink"
                                               placeholder="Enter Client Company Website Link">
                                        <label class="fw-bolder text-gray">Client Company Website Link</label>
                                    </div>
                                </div>
                                <span class="text-danger"
                                      *ngIf="clientErrors.companyPartnerLink">{{clientErrors.companyPartnerLink}}</span>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-12">
                                    <div class="form-floating">
                                        <input class="form-control" type="file" (change)="onFileSelected($event)">
                                        <label class="form-label">Image</label>
                                    </div>
                                    <!--<span class="text-danger mb-2"
                                          *ngIf="clientErrors.companyPartnerImagePath">{{clientErrors.companyPartnerImagePath}}</span>-->

                                    <div class="form-floating mt-2" *ngIf="onlyEditShow == true">
                                        <input type="text" class="form-control" [value]="clients.get('companyImageName').value"
                                               readonly>
                                        <label class="fw-bolder text-gray">Image Name</label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" (click)="onSubmit();"
                                class="btn btn-primary">{{this.updateButtonShow == false ? 'Add Client Details' : 'Update Client Details'}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>