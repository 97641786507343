import {Component, HostBinding, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import {LoginRequest} from "@services/loginRequest";
import {LoginResponse} from "@services/loginResponse";
import {Subject, takeUntil} from "rxjs";
import {Router, RouterModule} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @HostBinding('class') class = 'login-box';
  public isAuthLoading = false;
  public isGoogleLoading = false;
  public isFacebookLoading = false;
  public userDetails: LoginResponse;
  localhost = window.location.hostname === 'localhost';

  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
  }
  /*ngOnInit() {
    this.renderer.addClass(
        document.querySelector('app-root'),
        'login-page'
    );
  }*/

 /*
    this.loginForm = new UntypedFormGroup({
      userName: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, Validators.required)
    });
  }

  async loginByAuth() {
    if (this.loginForm.valid) {
      this.isAuthLoading = true;
      await this.appService.loginByAuth(this.loginForm.value);
      this.isAuthLoading = false;
    } else {
      this.toastr.error('Form is not valid!');
    }
  }

  async loginByGoogle() {
    this.isGoogleLoading = true;
    await this.appService.loginByGoogle();
    this.isGoogleLoading = false;
  }

  async loginByFacebook() {
    this.isFacebookLoading = true;
    await this.appService.loginByFacebook();
    this.isFacebookLoading = false;
  }

  ngOnDestroy() {
   /!* this.renderer.removeClass(
      document.querySelector('app-root'),
      'login-page'
    );*!/
  }*/
  loginForm = new UntypedFormGroup({
    userName: new UntypedFormControl("", [
      Validators.required,
      Validators.nullValidator,
    ]),

    password: new UntypedFormControl("", [
      Validators.required,
      Validators.nullValidator,
    ]),
  })

  onSubmit() {
    this.loginFormValidation();
  }

  public loginErrors = {
    userName: "",
    password: ""
  }

  loginFormValidation() {
    this.loginErrors.userName = ""
    this.loginErrors.password = ""

    let hasError = false;

    if (this.loginForm.get("userName")?.invalid) {
      this.loginErrors.userName = "UserName is Required";
      hasError = true;
    }
    if (this.loginForm.get("password")?.invalid) {
      this.loginErrors.password = "Password is Required";
      hasError = true;
    }

    if (!hasError) {
      this.loginByAuth();
    }
  }

  loginByAuth() {
    this.spinner.show();
    const username = this.loginForm.get("userName").value;
    const password = this.loginForm.get("password").value;
    let loginRequest = new LoginRequest()
    loginRequest.username = username;
    loginRequest.password = password;
    if (this.localhost) {
      console.log("login Request::", loginRequest);
    }
    this.appService.login(loginRequest)
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          if (this.localhost) {
            console.log("User Details::", data);
          }
              if (data != null) {
                this.userDetails = data;
                localStorage.setItem('authToken', String(this.userDetails.authHeader))
                localStorage.setItem('role', String(this.userDetails.userMaster.role))
                localStorage.setItem('userType', String(this.userDetails.userMaster.userType))
                localStorage.setItem('userName', String(this.userDetails.userMaster.userName))
                localStorage.setItem('userId', String(this.userDetails.userMaster.userID))
                localStorage.setItem('appCode', String(this.userDetails.appCode))
                this.toastr.success("Login Successfully");
                this.router.navigate(["/home"]);
                this.spinner.hide();
              }
            },
            (err: any) => {
          this.spinner.hide();
              this.toastr.error("User Name or Password is Wrong")
            })
   /* this.router.navigate(["/main/dashboard"]);*/
  }
}
