import {Component, Renderer2} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {Subject, takeUntil} from 'rxjs';
import {DatePipe} from "@angular/common";
import Swal from "sweetalert2";
import {PressRelease} from "@/modals/press-release/PressRelease";

@Component({
    selector: 'app-press-release',
    templateUrl: './press-release.component.html',
    styleUrls: ['./press-release.component.scss'],
})

export class PressReleaseComponent {
    destroy$: Subject<boolean> = new Subject<boolean>();
    isCardCollapsed: boolean[] = [];
    isModalVisible = false;
    updateButtonShow = false;
    imageUrl: string;
    onlyEditShow = false;
    fileList: File[] = [];
    currentValue: string[] = [];
    pressReleaseDetails: PressRelease | undefined;
    pressReleaseList: PressRelease [] = [];
    pressId: number;
    selectPressReleasePosition: number;
    isButtonGroupClicked = false;
    searchText;
    isAdmin: string;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private datePipe: DatePipe,
    ) {
    }

    pressRelease = new UntypedFormGroup({
        title: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        subject: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pressReleaseDate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pdfPath: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        websiteId: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        websiteName: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        pressId: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        pdfName: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
    });

    public pressErrors = {
        title: "", subject: "", pressReleaseDate: "",
    }

    ngOnInit() {
        this.isAdmin = localStorage.getItem('accessType');
        this.allPressReleaseDetails();
    }

    handleImageError() {
       /* this.imageUrl = "src/assets/img/dummy-image.png";*/
    }

    onSubmit() {
        this.formValidation();
    }

    formValidation() {
        this.pressErrors.title = "";
        this.pressErrors.subject = "";
        let hasError = false;

        if (this.pressRelease.get("pressReleaseDate")?.invalid) {
            this.pressErrors.pressReleaseDate = "Date is required";
            hasError = true;
        }

        if (this.pressRelease.get("title")?.invalid) {
            this.pressErrors.title = "Title is Required";
            hasError = true;
        }
        if (this.pressRelease.get("subject")?.invalid) {
            this.pressErrors.subject = "subject is required";
            hasError = true
        }
        if (!this.pressRelease.get("pressReleaseDate")?.invalid) {
            const venueDateTimeValue: string = this.pressRelease.get("pressReleaseDate")?.value;
            const epochValue: number = new Date(venueDateTimeValue).getTime();
            this.pressRelease.patchValue({pressReleaseDate: epochValue});
        }
        if (!hasError) {
            const dateTimeString = this.pressRelease.get('pressReleaseDate').value;
            this.pressRelease.patchValue({
                pressReleaseDate: this.convertToEpoch(dateTimeString),
            });

            console.log("add", this.pressRelease.value);
            this.currentValue.push(this.pressRelease.value);
            console.log("final::", this.currentValue);
            this.savePressReleaseDetails();
        }
    }

    convertToEpoch(dateTimeString: string) {
        return new Date(dateTimeString).getTime();
    }


    toggleCard(index: number) {
        this.isCardCollapsed[index] = !this.isCardCollapsed[index];
    }

    openModal() {
        this.isModalVisible = !this.isModalVisible;
        this.updateButtonShow = false;
        this.onlyEditShow = false;
    }

    closeModal() {
        this.isModalVisible = false;
        this.pressRelease.reset();
    }

    onFileSelected(event: any) {
        this.fileList = [];
        for (var i = 0; i <= event.target.files.length - 1; i++) {
            var selectedFile = event.target.files[i];
            this.onlyEditShow = false;
            console.log("select file size ::" + selectedFile);
            var fileSizeInMb = Math.round((selectedFile.size * 100) / (1024 * 1024)) / 100;
            console.log("select file convert size ::" + fileSizeInMb);
            if (fileSizeInMb <= 10) {
                this.fileList.push(selectedFile);
                console.log("File list::", this.fileList)
            } else {
                this.toastr.error("File size is large. Please upload below 10MB")
            }
        }
    }

    getFileNameFromPath(path: string): string {
        const parts = path.split('/');
        return parts[parts.length - 1];
    }

    openEditModal(id: number, title: string, subject: string, date: string, index: number) {
        this.updateButtonShow = true;
        this.onlyEditShow = true;
        this.selectPressReleasePosition = index;
        this.isModalVisible = !this.isModalVisible;
        this.isCardCollapsed[index] = !this.isButtonGroupClicked;
        this.pressRelease.get('pressId').setValue(id);
        this.pressRelease.get('title').setValue(title);
        this.pressRelease.get('subject').setValue(subject);
        /* this.pressRelease.get('pressReleaseDate').setValue(date);*/
        const formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd');
        console.log("formattedDate::", formattedDate);
        this.pressRelease.controls['pressReleaseDate'].setValue(formattedDate);
        console.log("dd::", this.pressRelease.get('pressReleaseDate').value);
        const pdfPath = this.pressReleaseList[index].pdfPath;
        this.pressRelease.controls['pdfPath'].setValue(pdfPath);
        const fileName = this.getFileNameFromPath(pdfPath);
        this.pressRelease.controls['pdfName'].setValue(fileName);
    }

    formatDate(epochTimestamp: number): string {
        return this.datePipe.transform(epochTimestamp, 'dd/MM/yyyy');
    }

    allPressReleaseDetails() {
        this.spinner.show();
        this.appService
            .getAllPressRelease()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                    this.pressReleaseList = data;
                    this.pressReleaseList.sort((a, b) => b.pressId - a.pressId);
                    if (this.pressReleaseList.length == 0) {
                        this.toastr.info("No press release found")
                    }
                    this.spinner.hide();
                    console.log("Press Release List::", this.pressReleaseList);
                },
                (error: any) => {
                    console.log("error:", error);
                    this.spinner.hide();
                    if (error && error.error && error.error.msg) {
                        this.spinner.hide();
                        this.toastr.error(error.error.msg);
                    } else {
                        this.spinner.hide();
                        this.toastr.error("Something went wrong, please try again");
                    }
                });
    }

    savePressReleaseDetails() {
        const selectedWebsiteId = Number(localStorage.getItem('selectedWebsiteId'));
        const selectedWebsiteName = localStorage.getItem('selectedWebsiteName');

        this.pressRelease.controls['websiteId'].setValue(selectedWebsiteId);
        this.pressRelease.controls['websiteName'].setValue(selectedWebsiteName);

        const isUpdate = this.updateButtonShow;
        const actionVerb = isUpdate ? 'Update' : 'Add';

        this.spinner.show();
        const request = isUpdate
            ? this.appService.updatePressRelease(this.pressRelease.value)
            : this.appService.savePressRelease(this.pressRelease.value);

        request.pipe(takeUntil(this.destroy$)).subscribe(
            (data: any) => {
                console.log(`${actionVerb} Press Release::`, data);
                const formData = new FormData();
                for (const image of this.fileList) {
                    formData.append('file', image, image.name);
                }

                this.pressReleaseDetails = data;
                if (this.fileList.length > 0) {
                    this.savePressPDF(formData, this.pressReleaseDetails.pressId);
                }

                this.spinner.hide();
                this.toastr.success(`Press Release ${actionVerb.toLowerCase()}d successfully`);

                if (isUpdate) {
                    this.pressReleaseList[this.selectPressReleasePosition].title = data.title;
                    this.pressReleaseList[this.selectPressReleasePosition].subject = data.subject;
                    this.pressReleaseList[this.selectPressReleasePosition].pressReleaseDate = data.pressReleaseDate;
                    this.selectPressReleasePosition = null;
                    this.closeModal();
                } else {
                    this.pressReleaseList.push(data);
                    this.pressRelease.reset();
                }
            },
            (error: any) => {
                console.log("error:", error);
                this.spinner.hide();

                if (error && error.error && error.error.msg) {
                    this.spinner.hide();
                    this.toastr.error(error.error.msg);
                } else {
                    this.spinner.hide();
                    this.toastr.error("Something went wrong, please try again");
                }
            }
        );
    }


    savePressPDF(formData: FormData, pressId: number) {
        this.spinner.show();
        this.appService
            .addPressPDFFile(formData, pressId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                    console.log("PDF::", data);
                    location.reload();
                    this.spinner.hide();
                    this.closeModal();
                    this.fileList = [];
                },
                (error: any) => {
                    console.log("error:", error);
                    this.spinner.hide();
                    if (error && error.error && error.error.msg) {
                        this.spinner.hide();
                        this.toastr.error(error.error.msg);
                    } else {
                        this.spinner.hide();
                        this.toastr.error("Something went wrong, please try again");
                    }
                })
    }

    openOpenFile(pressId: number, index: number) {
        this.appService
            .getPDFByPressId(pressId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                    console.log("data::", data);
                    const mimeType = 'application/pdf';
                    console.log("mime type::", mimeType)
                    const myBlob = new Blob([data], {type: mimeType});
                    window.open(URL.createObjectURL(myBlob), '_blank');
                },
                (error: any) => {
                    console.log("error:", error);
                    this.spinner.hide();
                    if (error && error.error && error.error.msg) {
                        this.spinner.hide();
                        this.toastr.error(error.error.msg);
                    } else {
                        this.spinner.hide();
                        this.toastr.error("Something went wrong, please try again");
                    }
                })
    }

    deletePressDetails(pressId: number, pressReleasePosition: number) {
        this.isCardCollapsed[pressReleasePosition] = !this.isButtonGroupClicked;
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                this.appService
                    .deletePressRelease(pressId)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(data => {
                        console.log("deleted press release::", data);
                            location.reload();
                            this.spinner.hide();
                        this.toastr.success("Press release deleted successfully");
                        this.pressReleaseList.splice(pressReleasePosition, 1);
                        Swal.fire({
                            title: "Deleted",
                            text: "Your press release has been deleted.",
                            icon: "success"
                        });
                    },
                        (error: any) => {
                            console.log("error:", error);
                            this.spinner.hide();
                            if (error && error.error && error.error.msg) {
                                this.spinner.hide();
                                this.toastr.error(error.error.msg);
                            }
                        });
            }
        }, (error: any) => {
            console.log("error:", error);
            this.spinner.hide();
            if (error && error.error && error.error.msg) {
                this.spinner.hide();
                this.toastr.error(error.error.msg);
            }
        });
    }


    openStatus(pressId: number, showStatus: string, event,index) {
        this.isCardCollapsed[index] = !this.isCardCollapsed[index];
        const isChecked = event.target.checked;
        var confirmButtonText:  any ;
        if (isChecked) {
            showStatus = 'Showing';
            confirmButtonText = 'Show'
        } else {
            showStatus = 'Not Showing';
            confirmButtonText = 'Hide'
        }

        console.log("checked::", isChecked);

        Swal.fire({
            title: "Are you sure?",
            text: "Are you want to show or hide your press release details",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: confirmButtonText
        }).then((result) => {
            if (result.isConfirmed) {
                // The user clicked on "Okay", update the Client Detailsstatus
                this.appService
                    .updatePressReleaseStatus(pressId, showStatus)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(data => {
                            console.log("Update Client Details Status::", data);
                            this.pressReleaseDetails = data;
                            this.toastr.success("Client Details Status Updated successfully");
                            /* const updatedNewsFeed = this.allNewsFeedList[this.selectNewsFeedPosition];
                             updatedNewsFeed.status = data.status;*/
                            Swal.fire({
                                title: "Update",
                                text: "Your Client Details status has been updated.",
                                icon: "success"
                            });
                        },
                        (error: any) => {
                            console.log("error:", error);
                            this.spinner.hide();
                            if (error && error.error && error.error.msg) {
                                this.toastr.error(error.error.msg);
                            }
                        });
            } else {
                event.target.checked = !isChecked;
                console.log("Switch state after denied:", event.target.checked);
                this.toastr.info("Action canceled");
            }
        }, (error: any) => {
            console.log("error:", error);
            this.spinner.hide();
            if (error && error.error && error.error.msg) {
                this.toastr.error(error.error.msg);
            } else {
                this.toastr.error("Something went wrong, please try again");
            }
        });
    }

    /*savePressReleaseDetails() {
       if (this.updateButtonShow == false) {
           this.pressRelease.controls['websiteId'].setValue(Number(localStorage.getItem('selectedWebsiteId')));
           this.pressRelease.controls['websiteName'].setValue(localStorage.getItem('selectedWebsiteName'));
           this.spinner.show();
           this.appService
               .savePressRelease(this.pressRelease.value)
               .pipe(takeUntil(this.destroy$))
               .subscribe(data => {
                       const formData = new FormData();
                       for (let image of this.fileList) {
                           formData.append('file', image, image.name);
                       }
                       this.pressReleaseDetails = data;
                       console.log("NewsFeed::", this.pressReleaseList);
                       if (this.fileList.length > 0) {
                           this.savePressPDF(formData, this.pressReleaseDetails.pressId);
                       }
                       this.spinner.hide();
                       this.toastr.success("Press release added successfully");
                       this.pressReleaseList.push(data)
                       this.pressRelease.reset();
                       this.closeModal();
                   },
                   (error: any) => {
                       console.log("error:", error);

                       if (error && error.error && error.error.msg) {
                           this.toastr.error(error.error.msg);
                       } else {
                           this.toastr.error("Something went wrong, please try again");
                       }
                   });
       }
       if (this.updateButtonShow == true) {
           this.pressRelease.controls['websiteId'].setValue(Number(localStorage.getItem('selectedWebsiteId')));
           this.pressRelease.controls['websiteName'].setValue(localStorage.getItem('selectedWebsiteName'));
           this.spinner.show();
           this.appService
               .updatePressRelease(this.pressRelease.value)
               .pipe(takeUntil(this.destroy$))
               .subscribe(data => {
                       const formData = new FormData();
                       for (let image of this.fileList) {
                           formData.append('file', image, image.name);
                       }
                       this.pressReleaseDetails = data;
                       console.log("NewsFeed::", this.pressReleaseList);
                       if (this.fileList.length > 0) {
                           this.savePressPDF(formData, this.pressReleaseDetails.pressId);
                       }
                       this.spinner.hide();
                       this.toastr.success("Press release updated successfully");
                       this.pressReleaseList[this.selectPressReleasePosition].title = data.title;
                       this.pressReleaseList[this.selectPressReleasePosition].subject = data.subject;
                       this.pressReleaseList[this.selectPressReleasePosition].pressReleaseDate = data.pressReleaseDate;
                       this.selectPressReleasePosition = null;
                       this.closeModal();
                   },
                   (error: any) => {
                       console.log("error:", error);

                       if (error && error.error && error.error.msg) {
                           this.toastr.error(error.error.msg);
                       } else {
                           this.toastr.error("Something went wrong, please try again");
                       }
                   });
       }
   }*/
}
