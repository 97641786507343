<nav class="navbar bg-gradient-blue-color">
    <div class="container-fluid">
       <!-- <ul class="navbar-nav col-lg-3">
            <li class="nav-item">
                <a (click)="onToggleMenuSidebar()" class="nav-link" role="button"
                ><i class="fas fa-thin fa-bars text-white"></i
                ></a>
            </li>
            &lt;!&ndash;<li class="nav-item d-none d-sm-inline-block">
              <a [routerLink]="['/']" class="nav-link">Home</a>
            </li>
            <li class="nav-item d-none d-sm-inline-block">
              <a [routerLink]="['/blank']" class="nav-link">Contact</a>
            </li>&ndash;&gt;

        </ul>-->
        <ul class="navbar-nav ml-auto">
            <!--<app-messages></app-messages>
            <app-notifications></app-notifications>
            <app-language></app-language>-->
            <app-user></app-user>
            <!--<li class="nav-item">
              <button (click)="onToggleControlSidebar()" class="nav-link">
                <i class="fas fa-th-large"></i>
              </button>
            </li>-->
        </ul>

    </div>
</nav>
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <h2 class="font-weight-bolder mt-2 text-capitalize">Welcome <span class="text-primary text-uppercase">{{userName}}</span></h2>
        </div>
    </div>

    <div class="row mt-3">
       <div class="col-lg-6">
           <div class="card bg-gradient-blue-color" *ngFor="let name of this.websiteList;let i= index;" type="button" (click)="openSiteBasedDetails(name)">
               <h5 class="text-bold p-3 text-white">{{name.websiteName}}</h5>
           </div>
       </div>
    </div>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>