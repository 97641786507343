import {Component, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {Subject, takeUntil} from 'rxjs';
import {GalleryDetails} from "@/modals/gallery/galleryDetails";
import {AlbumDetails} from "@/modals/album/albumDetails";
import {AlbumImageDetails} from "@/modals/album/albumImageDetails";
import Swal from 'sweetalert2'
import SwiperCore, {Navigation, Pagination, SwiperOptions,Scrollbar} from 'swiper';
import {string} from "fast-glob/out/utils";
SwiperCore.use([Navigation, Pagination,Scrollbar]);

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GalleryComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    searchText;
    isModalVisible = false;
    galleryDetails: GalleryDetails | undefined;
    getAllAlbumsList: AlbumDetails[] = [];
    isCardCollapsed: boolean[] = [];
    galleryId: number;
    updateAlbumName: string = "";
    albumDetailsSeq: number;
    updateButtonShow = false;
    albumId: number;
    fileList: File[] = [];
    albumImageList: AlbumImageDetails[] = [];
    selectAlbumPosition: number;
    imageUrl: string;
    isCardMaximized: boolean[] = [];
    isButtonGroupClicked = false;
    websiteId: number;
    isAdmin: string;
    images: any[] = [
        {
            src: 'http://136.233.82.212:8080/varnik-cms-ws/api/front-end/gallery/getImage/{{pic.pictureDetailsSeq}}'
        }
    ]

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService
    ) {
    }

    /*toggleCardMaximize(index: number): void {
        this.isCardMaximized[index] = !this.isCardMaximized[index];
    }*/

    ngOnInit() {
        this.getAllGalleryDetails();
        this.isAdmin = localStorage.getItem('accessType');
    }

    configCity: SwiperOptions = {
        pagination: { el: '.swiper-pagination', clickable: true },
        allowTouchMove: true,
        breakpoints: {
            1024: {
                slidesPerView: 6
            },
            500: {
                slidesPerView: 4
            },
            400: {
                slidesPerView: 1
            },
            300: {
                slidesPerView: 1
            }
        },

    };

    /*handleImageError() {
        this.imageUrl = 'src/assets/img/dummy-image.png';
    }*/

    toggleCard(index: number) {
        this.isCardCollapsed[index] = !this.isCardCollapsed[index];
    }

    openModal() {
        this.isModalVisible = !this.isModalVisible;
        this.updateButtonShow = false;
    }


    openEditModal(albumName: string, albumDetailsSeq: number, index: number) {
        this.selectAlbumPosition = index;
        this.isModalVisible = !this.isModalVisible;
        this.isCardCollapsed[index] = !this.isButtonGroupClicked;
        this.addAlbum.get('albumName').setValue(albumName);
        this.addAlbum.get('albumDetailsSeq').setValue(albumDetailsSeq);
        this.albumDetailsSeq = albumDetailsSeq;
        this.updateButtonShow = true;
    }

    closeModal() {
        this.isModalVisible = false;
    }

    //Form Validations
    addAlbum = new UntypedFormGroup({
        albumName: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        galleryId: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        albumDetailsSeq: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        websiteId: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        websiteName: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),

        /*albumImage: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),*/
    })

    onSubmit() {
        this.albumFormValidation();
    }

    public albumFormErrors = {
        albumName: "",
        albumImage: ""
    }

    albumFormValidation() {
        this.albumFormErrors.albumName = ""
        this.albumFormErrors.albumImage = ""

        let hasError = false;

        if (this.addAlbum.get("albumName")?.invalid) {
            this.albumFormErrors.albumName = "Album Name is Required";
            hasError = true;
        }
        /*if (this.addAlbum.get("albumImage")?.invalid) {
            this.albumFormErrors.albumImage = "Album Image is Required and Add At least One Image";
            hasError = true;
        }*/

        if (!hasError) {
            this.saveAlbumDetails();
        }
    }

    handleFileInput(event: any, albumId: number) {
        console.log("A ID::", albumId);
        const inputElement = event.target as HTMLInputElement;
        const files = inputElement.files;

        if (files && files.length > 0) {
            // Filter out non-image files
            const imageFiles = this.filterImageFiles(files);

            if (imageFiles.length > 0) {
                // Check file size before processing
                if (this.checkFileSize(imageFiles, 1)) {
                    // Create a custom FileList
                    const customFileList = new DataTransfer();
                    imageFiles.forEach(file => {
                        customFileList.items.add(file);
                    });

                    // Call saveImages with the custom FileList
                    this.saveImages(customFileList.files, albumId);
                } else {
                    // Display a toast message for file size error
                    this.toastr.error('File size should be below 1MB.');
                }
            } else {
                // Handle the case where no image files are selected
                console.log('No image files selected.');
            }
        }
    }

    checkFileSize(files: File[], maxSizeInMB: number): boolean {
        for (const file of files) {
            const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to megabytes
            if (fileSizeInMB > maxSizeInMB) {
                return false; // File size exceeds the specified limit
            }
        }
        return true; // All files are within the size limit
    }

    filterImageFiles(files: FileList): File[] {
        const imageFiles: File[] = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            // Check if the file type starts with 'image/'
            if (file.type.startsWith('image/')) {
                imageFiles.push(file);
            }
        }

        return imageFiles;
    }

    saveImages(files: FileList, albumId: number) {
        this.fileList = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            console.log("Image::", file);
            this.fileList.push(file);
        }
        console.log("File List::", this.fileList);
        const formData = new FormData();
        for (let append of this.fileList) {
            formData.append('images', append, append.name);
        }
        this.spinner.show();
        this.appService
            .addAlbumImages(formData, albumId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                    this.albumImageList = data;
                    console.log("album Image List::", this.albumImageList);
                    location.reload();
                    this.spinner.hide();
                    this.toastr.success("Your Album Images Added Completed");
                },
                (error: any) => {
                    console.log("error:", error);
                    this.spinner.hide();
                    this.toastr.error("Something went wrong,please try again");
                })
    }

    //Get Gallery Details
    getAllGalleryDetails() {
        this.spinner.show();
        this.appService
            .getAllGallery()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                    this.galleryDetails = data;
                    this.spinner.hide();
                    console.log("get Gallery::", this.galleryDetails);
                    this.galleryId = this.galleryDetails.gallerySeq;
                    if (this.galleryDetails.albumDetailsList.length != 0) {
                        this.getAllAlbums();
                    } else {
                        this.toastr.info("No Album Found, Please Create a Album using Plus Icon")
                    }
                },
                (error: any) => {
                    console.log("log",error.error.code)
                    this.spinner.hide();
                    if (error.error.code == 404 && 'no gallery found') {
                        this.saveNewGallery();
                      /*  this.toastr.error(error.error.msg);*/
                    } else {
                        this.spinner.hide();
                        // Display a generic error message if no specific message is available
                        this.toastr.error("Something went wrong, please try again");
                    }
                }
            );
    }

    //Get Albums Details
    getAllAlbums() {
        this.spinner.show();
        this.appService
            .getAlbums()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                    this.getAllAlbumsList = data;
                    this.getAllAlbumsList.sort((a, b) => b.albumDetailsSeq - a.albumDetailsSeq);
                    this.spinner.hide();
                    console.log("get all albums::", this.getAllAlbumsList);
                    for (let length of this.getAllAlbumsList) {
                        length.length = length.pictureDetailsList.length;
                        this.updateAlbumName = length.albumName;
                        length.localAlbumId = length.albumDetailsSeq;
                        /* for (let pic of length.pictureDetailsList) {
                             length.localAlbumId = pic.albumId;
                         }*/
                    }
                },
                (error: any) => {
                    console.log("error:", error);
                    this.spinner.hide();
                    if (error && error.error && error.error.msg) {
                        // Display the custom error message from the network request
                        this.spinner.hide();
                        this.toastr.error(error.error.msg);
                    } else {
                        this.spinner.hide();
                        // Display a generic error message if no specific message is available
                        this.toastr.error("Something went wrong, please try again");
                    }
                }
            );
    }

    //Save Album Name

    saveAlbumDetails() {
        const selectedWebsiteId = Number(localStorage.getItem('selectedWebsiteId'));
        const selectedWebsiteName = localStorage.getItem('selectedWebsiteName');

        this.addAlbum.controls['websiteId'].setValue(selectedWebsiteId);
        this.addAlbum.controls['websiteName'].setValue(selectedWebsiteName);
        this.addAlbum.controls['galleryId'].setValue(this.galleryId);
        console.log("galleryId::", this.galleryId);

        const isUpdate = this.updateButtonShow;
        const actionVerb = isUpdate ? 'update' : 'add';

        const request = isUpdate
            ? this.appService.updateAlbum(this.addAlbum.value)
            : this.appService.addNewAlbum(this.addAlbum.value);
        this.spinner.show();
        request.pipe(takeUntil(this.destroy$)).subscribe(
            (data: any) => {
                console.log(`${actionVerb} Album Details::`, data);
                this.toastr.success(`Album ${actionVerb}ed successfully`);
                this.spinner.hide();
                if (isUpdate) {
                    this.getAllAlbumsList[this.selectAlbumPosition].albumName = data.albumName;
                    this.selectAlbumPosition = null;
                } else {
                    this.getAllAlbumsList.push(data);
                }

                this.addAlbum.reset();
                this.closeModal();
            },
            (error: any) => {
                console.log("error:", error);
                this.spinner.hide();
                if (error && error.error && error.error.msg) {
                    this.spinner.hide();
                    this.toastr.error(error.error.msg);
                } else {
                    this.spinner.hide();
                    this.toastr.error("Something went wrong, please try again");
                }
            }
        );
    }




    deleteAlbumDetails(albumId: number, albumPosition: number) {
        this.isCardCollapsed[albumPosition] = !this.isButtonGroupClicked;
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this album!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                this.appService
                    .deleteAlbum(albumId)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(data => {
                        console.log("deleted album::", data);
                        this.toastr.success("Image deleted successfully");
                        this.getAllAlbumsList.splice(albumPosition, 1);
                            Swal.fire({
                                title: "Deleted",
                                text: "Your album has been deleted.",
                                icon: "success"
                            });
                        },
                        (error: any) => {
                            console.log("error:", error);
                            this.spinner.hide();
                            if (error && error.error && error.error.msg) {
                                this.spinner.hide();
                                this.toastr.error(error.error.msg);
                            }
                        });
            }
        }, (error: any) => {
            console.log("error:", error);
            this.spinner.hide();
            if (error && error.error && error.error.msg) {
                this.spinner.hide();
                this.toastr.error(error.error.msg);
            } else {
                this.spinner.hide();
                this.toastr.error("Something went wrong, please try again");
            }
        });
    }

    //Delete Album Images
    deleteImageAlbumImages(pictureId: number, jIndex: number, albumPosition: number) {
        Swal.fire({
            title: "Are you delete this image?",
            text: "You won't be able to revert this image!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                this.spinner.show();
                this.appService
                    .deleteAlbumPicture(pictureId)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(data => {
                            console.log("Image Deleted::", data);
                            this.spinner.hide();
                            this.toastr.success("Image deleted successfully");
                            this.getAllAlbumsList[albumPosition].pictureDetailsList.splice(jIndex, 1);
                        },
                        (error: any) => {
                            this.spinner.hide();
                            console.log("error:", error);
                            this.toastr.error("Something went wrong,please try again");
                        });
            }
        })
    }

    saveNewGallery() {
        this.websiteId = (Number(localStorage.getItem('selectedWebsiteId')));
        this.appService
            .saveGallery(this.websiteId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                    console.log("save gallery Details::", data)
                    this.toastr.success("Gallery created successfully");
                    this.getAllAlbumsList[this.selectAlbumPosition].albumName = data.albumName;
                    this.selectAlbumPosition = null;
                    this.closeModal();
                },
                (error: any) => {
                    console.log("error:", error);

                    if (error && error.error && error.error.msg) {
                        // Display the custom error message from the network request
                        this.spinner.hide();
                        this.toastr.error(error.error.msg);
                    } else {
                        // Display a generic error message if no specific message is available
                        this.spinner.hide();
                        this.toastr.error("Something went wrong, please try again");
                    }
                }
            );
    }

    /*saveAlbumDetails() {
       if (this.updateButtonShow == false) {
           this.addAlbum.controls['websiteId'].setValue(Number(localStorage.getItem('selectedWebsiteId')));
           this.addAlbum.controls['websiteName'].setValue(localStorage.getItem('selectedWebsiteName'));
           this.addAlbum.controls['galleryId'].setValue(this.galleryId);
           console.log("galleryId::", this.galleryId)
           this.appService
               .addNewAlbum(this.addAlbum.value)
               .pipe(takeUntil(this.destroy$))
               .subscribe(data => {
                       console.log("Album Details::", data)
                       this.toastr.success("Album added successfully");
                       this.addAlbum.reset();
                       this.getAllAlbumsList.push(data);
                       this.closeModal();
                   },
                   (error: any) => {
                       console.log("error:", error);

                       if (error && error.error && error.error.msg) {
                           // Display the custom error message from the network request
                           this.toastr.error(error.error.msg);
                       } else {
                           // Display a generic error message if no specific message is available
                           this.toastr.error("Something went wrong, please try again");
                       }
                   }
               );
       }
       if (this.updateButtonShow == true) {
           this.addAlbum.controls['websiteId'].setValue(Number(localStorage.getItem('selectedWebsiteId')));
           this.addAlbum.controls['websiteName'].setValue(localStorage.getItem('selectedWebsiteName'));
           this.addAlbum.controls['galleryId'].setValue(this.galleryId);
           console.log("galleryId::", this.galleryId)
           this.appService
               .updateAlbum(this.addAlbum.value)
               .pipe(takeUntil(this.destroy$))
               .subscribe(data => {
                       console.log("Update Album Details::", data)
                       this.toastr.success("Album updated successfully");
                       this.getAllAlbumsList[this.selectAlbumPosition].albumName = data.albumName;
                       this.selectAlbumPosition = null;
                       this.closeModal();
                   },
                   (error: any) => {
                       console.log("error:", error);

                       if (error && error.error && error.error.msg) {
                           // Display the custom error message from the network request
                           this.toastr.error(error.error.msg);
                       } else {
                           // Display a generic error message if no specific message is available
                           this.toastr.error("Something went wrong, please try again");
                       }
                   }
               );
       }
   }*/
}
