<!--
<div class="card card-outline card-primary">
  <div class="card-header text-center">
    <a [routerLink]="['/']" class="h1"><b>Admin</b>LTE</a>
  </div>
  <div class="card-body">
    <p class="login-box-msg">Sign in to start your session</p>

    <form (ngSubmit)="loginByAuth()" [formGroup]="loginForm">
      <div class="input-group mb-3">
        <input
          class="form-control"
          formControlName="email"
          placeholder="Email"
          type="email"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-envelope"></span>
          </div>
        </div>
      </div>
      <div class="input-group mb-3">
        <input
          class="form-control"
          formControlName="password"
          placeholder="Password"
          type="password"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-lock"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <div class="icheck-primary">
            <input id="remember" type="checkbox"/>
            <label for="remember"> Remember Me </label>
          </div>
        </div>
        &lt;!&ndash; /.col &ndash;&gt;
        <div class="col-5">
          <pf-button
            [block]="true"
            [disabled]="isFacebookLoading || isGoogleLoading"
            [loading]="isAuthLoading"
            [type]="'submit'"
          >
            Sign In
          </pf-button>
        </div>
        &lt;!&ndash; /.col &ndash;&gt;
      </div>
    </form>

    <div class="social-auth-links text-center mb-3">
      <pf-button
        (click)="loginByFacebook()"
        [block]="true"
        [disabled]="isAuthLoading || isGoogleLoading"
        [loading]="isFacebookLoading"
        class="mb-2"
      >
        <i class="fab fa-facebook mr-2"></i>
        Sign in using Facebook
      </pf-button>
      <pf-button
        (click)="loginByGoogle()"
        [block]="true"
        [disabled]="isAuthLoading || isFacebookLoading"
        [loading]="isGoogleLoading"
        theme="danger"
      >
        <i class="fab fa-google mr-2"></i>
        Sign in using Google+
      </pf-button>
    </div>

    <p class="mb-1">
      <a [routerLink]="['/forgot-password']">I forgot my password</a>
    </p>
    <p class="mb-0">
      <a [routerLink]="['/register']" class="text-center">
        Register a new membership
      </a>
    </p>
  </div>
  &lt;!&ndash; /.login-card-body &ndash;&gt;
</div>
-->


<div class="container-fluid col-lg-10 col-12">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-12 mt-5">
            <div class="row mt-3">
                <div class="col-lg-2"></div>
                <div class="col-lg-8">
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="col-lg-12 col-md-12 col-12 text-center">
                            <!--<h3 class="fw-bold mt-5 text-primary">VarNik Content</h3>
                            <h3 class="fw-bold text-primary mb-3">Management System </h3>-->
                            <img src="assets/img/CMS-logo.png" class="img-fluid mt-3 mb-5" alt="cms-logo">
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="userName"
                                           placeholder="Username">
                                    <label class="fw-bolder text-gray">Username</label>
                                </div>
                            </div>
                            <span class="text-danger" *ngIf="loginErrors.userName">{{loginErrors.userName}}</span>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-12">
                                <div class="form-floating">
                                    <input type="password" class="form-control" formControlName="password"
                                           placeholder="Password">
                                    <label class="fw-bolder text-gray">Password</label>
                                </div>
                            </div>
                            <span class="text-danger" *ngIf="loginErrors.password">{{loginErrors.password}}</span>
                        </div>

                        <div class="row mt-3">
                            <div class="col-1g-12">
                                <button type="submit" class="btn btn-primary btn-block p-3">Login</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
            <div class="row mt-3">
                <div class="col-lg-12 mt-4">
                    <img src="assets/img/login-img.png" class="img-fluid login-img">
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>