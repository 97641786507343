import {Component, OnInit, Renderer2} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {DatePipe} from "@angular/common";
import {Subject, takeUntil} from 'rxjs';
import {Client} from "@/modals/client/Client";
import Swal from "sweetalert2";

@Component({
    selector: 'app-clients',
    templateUrl: './clients.component.html',
    styleUrls: ['./clients.component.scss'],
})

export class ClientsComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    isModalVisible = false;
    updateButtonShow = false;
    isCardCollapsed: boolean[] = [];
    imageUrl: string;
    fileList: File[] = [];
    onlyEditShow = false;
    clientDetails: Client | undefined;
    allClientDetails: Client[] = [];
    selectedClientPosition: number;
    isButtonGroupClicked = false;
    searchText;
    isAdmin: string;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private datePipe: DatePipe
    ) {
    }

    clients = new UntypedFormGroup({
        companyPartnerName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        companyPartnerLink: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        websiteId: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        websiteName: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        companyPartnerDetailsSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        companyPartnerImagePath: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        companyImageName: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
    });

    public clientErrors = {
        companyPartnerName: "", companyPartnerLink: "", companyPartnerImagePath: "",
    }

    formValidation() {
        this.clientErrors.companyPartnerName = ""
        this.clientErrors.companyPartnerLink = ""
        this.clientErrors.companyPartnerImagePath = ""
        let hasError = false;

        if (this.clients.get("companyPartnerName")?.invalid) {
            this.clientErrors.companyPartnerName = "Enter Client Company Name";
            hasError = true;
        }
        if (this.clients.get("companyPartnerLink")?.invalid) {
            this.clientErrors.companyPartnerLink = "Enter Client Company Website Link";
            hasError = true;
        }
        /*if (this.clients.get("companyPartnerImagePath")?.invalid) {
            this.clientErrors.companyPartnerImagePath = "Enter Client Company Logo";
            hasError = true;
        }*/
        if (!hasError) {
            this.saveClientsDetails();
        }
    }


    onSubmit() {
        this.formValidation();
    }

    ngOnInit() {
        this.isAdmin = localStorage.getItem('accessType');
        this.allClientsDetailsList();
    }

    openModal() {
        this.isModalVisible = !this.isModalVisible;
        this.updateButtonShow = false;
        this.onlyEditShow = false;
    }

    closeModal() {
        this.isModalVisible = false;
        this.clients.reset();
    }

    openEditModal(clientId:number,partnerName: string, partnerWebsiteLink: string,index: number,) {
        this.updateButtonShow = true;
        this.onlyEditShow = true;
        this.selectedClientPosition = index;
        this.isModalVisible = !this.isModalVisible;
        this.isCardCollapsed[index] = !this.isButtonGroupClicked;
        this.clients.get('companyPartnerName').setValue(partnerName);
        this.clients.get('companyPartnerLink').setValue(partnerWebsiteLink);
        this.clients.get('companyPartnerDetailsSeq').setValue(clientId);
        const imagePath = this.allClientDetails[index].companyPartnerImagePath;
        this.clients.controls['companyPartnerImagePath'].setValue(imagePath);
        const fileName = this.getFileNameFromPath(imagePath);
        this.clients.controls['companyImageName'].setValue(fileName);
    }

    getFileNameFromPath(path: string): string {
        const parts = path.split('/');
        return parts[parts.length - 1];
    }

    handleImageError() {
       /* this.imageUrl = "src/assets/img/dummy-image.png";*/
    }

    toggleCard(index: number) {
        this.isCardCollapsed[index] = !this.isCardCollapsed[index];
    }

    onFileSelected(event: any) {
        this.fileList = [];
        for (var i = 0; i <= event.target.files.length - 1; i++) {
            var selectedFile = event.target.files[i];
            this.onlyEditShow = false;
            console.log("select file size ::" + selectedFile);
            var fileSizeInMb = Math.round((selectedFile.size * 100) / (1024 * 1024)) / 100;
            console.log("select file convert size ::" + fileSizeInMb);
            if (fileSizeInMb <= 3) {
                this.fileList.push(selectedFile);
                console.log("File list::", this.fileList)
            } else {
                this.toastr.error("File size is large. Please upload below 3MB")
            }
        }
    }

    allClientsDetailsList() {
        this.spinner.show();
        this.appService
            .getAllClientDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                    this.spinner.hide();
                    this.allClientDetails = data;
                    this.allClientDetails.sort((a, b) => b.companyPartnerDetailsSeq - a.companyPartnerDetailsSeq);
                    if (this.allClientDetails.length == 0) {
                        this.toastr.info("No client detail found")
                    }
                    console.log("Clients List::", this.allClientDetails)
                },
                (error: any) => {
                    console.log("error:", error);
                    this.spinner.hide();
                    if (error && error.error && error.error.msg) {
                        this.spinner.hide();
                        this.toastr.error(error.error.msg);
                    } else {
                        this.spinner.hide();
                        this.toastr.error("Something went wrong, please try again");
                    }
                });
    }


    saveClientsDetails() {
        const selectedWebsiteId = Number(localStorage.getItem('selectedWebsiteId'));
        const selectedWebsiteName = localStorage.getItem('selectedWebsiteName');

        this.clients.controls['websiteId'].setValue(selectedWebsiteId);
        this.clients.controls['websiteName'].setValue(selectedWebsiteName);

        const isUpdate = this.updateButtonShow;
        const actionVerb = isUpdate ? 'Update' : 'Add';

        this.spinner.show();
        const request = isUpdate
            ? this.appService.updateClientDetails(this.clients.value)
            : this.appService.saveClientDetails(this.clients.value);

        request.pipe(takeUntil(this.destroy$)).subscribe(
            (data: any) => {
                console.log(`${actionVerb} client::`, data);
                const formData = new FormData();
                for (const image of this.fileList) {
                    formData.append('file', image, image.name);
                }

                this.clientDetails = data;
                if (this.fileList.length > 0) {
                    this.saveClientImage(formData, this.clientDetails.companyPartnerDetailsSeq);
                }

                this.spinner.hide();
                this.toastr.success(`Client Details ${actionVerb.toLowerCase()}ed successfully`);

                if (isUpdate) {
                    this.allClientDetails[this.selectedClientPosition].companyPartnerName = data.companyPartnerName;
                    this.allClientDetails[this.selectedClientPosition].companyPartnerLink = data.companyPartnerLink;
                    this.selectedClientPosition = null;
                    this.closeModal();
                } else {
                    this.allClientDetails.push(data);
                    this.clients.reset();
                }
            },
            (error: any) => {
                console.log("error:", error);
                this.spinner.hide();

                if (error && error.error && error.error.msg) {
                    this.spinner.hide();
                    this.toastr.error(error.error.msg);
                } else {
                    this.spinner.hide();
                    this.toastr.error("Something went wrong, please try again");
                }
            }
        );
    }


    saveClientImage(formData: FormData, partnerId: number) {
        this.spinner.show();
        this.appService
            .addClientImage(formData, partnerId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                    console.log("Image::", data);
                    this.spinner.hide();
                    this.closeModal();
                    this.fileList = [];
                },
                (error: any) => {
                    console.log("error:", error);
                    location.reload();
                    this.spinner.hide();
                    if (error && error.error && error.error.msg) {
                        this.spinner.hide();
                        this.toastr.error(error.error.msg);
                    } else {
                        this.spinner.hide();
                        this.toastr.error("Something went wrong, please try again");
                    }
                })
    }

    deleteClientDetails(clientId: number, clientPosition: number) {
        this.isCardCollapsed[clientPosition] = !this.isButtonGroupClicked;
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                this.appService
                    .deleteClientDetails(clientId)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(data => {
                            console.log("deleted client details::", data);
                            location.reload();
                            this.toastr.success("Client details deleted successfully");
                            this.allClientDetails.splice(clientPosition, 1);
                            Swal.fire({
                                title: "Deleted",
                                text: "Your client details has been deleted.",
                                icon: "success"
                            });
                        },
                        (error: any) => {
                            console.log("error:", error);
                            this.spinner.hide();
                            if (error && error.error && error.error.msg) {
                                this.spinner.hide();
                                this.toastr.error(error.error.msg);
                            }
                        });
            }
        }, (error: any) => {
            console.log("error:", error);
            this.spinner.hide();
            if (error && error.error && error.error.msg) {
                this.spinner.hide();
                this.toastr.error(error.error.msg);
            } else {
                this.spinner.hide();
                this.toastr.error("Something went wrong, please try again");
            }
        });
    }


    openStatus(clientId: number, showStatus: string, event, index) {
        this.isCardCollapsed[index] = !this.isCardCollapsed[index];
        const isChecked = event.target.checked;
        var confirmButtonText:  any ;
        if (isChecked) {
            showStatus = 'Showing';
            confirmButtonText = 'Show'
        } else {
            showStatus = 'Not Showing';
            confirmButtonText = 'Hide'
        }

        console.log("checked::", isChecked);

        Swal.fire({
            title: "Are you sure?",
            text: "Are you want to show or hide your client details",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: confirmButtonText
        }).then((result) => {
            if (result.isConfirmed) {
                // The user clicked on "Okay", update the Client Detailsstatus
                this.appService
                    .updateClientDetailStatus(clientId, showStatus)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(data => {
                            console.log("Update Client Details Status::", data);
                            this.clientDetails = data;
                            this.toastr.success("Client Details Status Updated successfully");
                            /* const updatedNewsFeed = this.allNewsFeedList[this.selectNewsFeedPosition];
                             updatedNewsFeed.status = data.status;*/
                            Swal.fire({
                                title: "Update",
                                text: "Your Client Details status has been updated.",
                                icon: "success"
                            });
                        },
                        (error: any) => {
                            console.log("error:", error);
                            this.spinner.hide();
                            if (error && error.error && error.error.msg) {
                                this.toastr.error(error.error.msg);
                            }
                        });
            } else {
                event.target.checked = !isChecked;
                console.log("Switch state after denied:", event.target.checked);
                this.toastr.info("Action canceled");
            }
        }, (error: any) => {
            console.log("error:", error);
            this.spinner.hide();
            if (error && error.error && error.error.msg) {
                this.toastr.error(error.error.msg);
            } else {
                this.toastr.error("Something went wrong, please try again");
            }
        });
    }

    /*saveClientsDetails() {
       if (this.updateButtonShow == false) {
           this.clients.controls['websiteId'].setValue(Number(localStorage.getItem('selectedWebsiteId')));
           this.clients.controls['websiteName'].setValue(localStorage.getItem('selectedWebsiteName'));
           this.spinner.show();
           this.appService
               .saveClientDetails(this.clients.value)
               .pipe(takeUntil(this.destroy$))
               .subscribe(data => {
                       const formData = new FormData();
                       for (let image of this.fileList) {
                           formData.append('file', image, image.name);
                       }
                       this.clientDetails = data;
                       console.log("Client add::", this.clientDetails);
                       if (this.fileList.length > 0) {
                           this.saveClientImage(formData, this.clientDetails.companyPartnerDetailsSeq);
                       }
                       this.spinner.hide();
                       this.toastr.success("Clients details added successfully");
                       this.allClientDetails.push(data);
                       this.clients.reset();
                       this.closeModal();
                   },
                   (error: any) => {
                       console.log("error:", error);

                       if (error && error.error && error.error.msg) {
                           this.toastr.error(error.error.msg);
                       } else {
                           this.toastr.error("Something went wrong, please try again");
                       }
                   });
       }
       if (this.updateButtonShow == true) {
           this.clients.controls['websiteId'].setValue(Number(localStorage.getItem('selectedWebsiteId')));
           this.clients.controls['websiteName'].setValue(localStorage.getItem('selectedWebsiteName'));
           this.spinner.show();
           this.appService
               .updateClientDetails(this.clients.value)
               .pipe(takeUntil(this.destroy$))
               .subscribe(data => {
                       const formData = new FormData();
                       for (let image of this.fileList) {
                           formData.append('file', image, image.name);
                       }
                       this.clientDetails = data;
                       console.log("Update client::", this.clientDetails);
                       if (this.fileList.length > 0) {
                           this.saveClientImage(formData, this.clientDetails.companyPartnerDetailsSeq);
                       }
                       this.spinner.hide();
                       this.toastr.success("Client Details Updated successfully");
                       this.allClientDetails[this.selectedClientPosition].companyPartnerName = data.companyPartnerName;
                       this.allClientDetails[this.selectedClientPosition].companyPartnerLink = data.companyPartnerLink;
                       this.selectedClientPosition = null;
                       this.closeModal();
                   },
                   (error: any) => {
                       console.log("error:", error);

                       if (error && error.error && error.error.msg) {
                           this.toastr.error(error.error.msg);
                       } else {
                           this.toastr.error("Something went wrong, please try again");
                       }
                   });
       }
   }*/
}