<ul class="navbar-nav col-lg-3">
  <li class="nav-item">
    <a (click)="onToggleMenuSidebar()" class="nav-link" role="button"
    ><i class="fas fa-thin fa-bars text-white"></i
    ></a>
  </li>
  <li class="nav-item d-none d-sm-inline-block">
    <a [routerLink]="['/home']" class="nav-link text-white text-bold">Home</a>
  </li>
  <!--<li class="nav-item d-none d-sm-inline-block">
    <a [routerLink]="['/blank']" class="nav-link">Contact</a>
  </li>-->

</ul>
<!--<div class="col-lg-6">
  <h5 class="text-bold text-lg-center">VarNik Content Management System</h5>
</div>-->

<form *ngIf="false" [formGroup]="searchForm" class="form-inline ml-3">
<!--  <div class="input-group input-group-sm">
    <input
      aria-label="Search"
      class="form-control form-control-navbar"
      placeholder="Search"
      type="search"
    />
    <div class="input-group-append">
      <button class="btn btn-navbar" type="submit">
        <i class="fas fa-search"></i>
      </button>
    </div>
  </div>-->
</form>
<ul class="navbar-nav ml-auto mr-4">
  <!--<app-messages></app-messages>
  <app-notifications></app-notifications>
  <app-language></app-language>-->
  <app-user></app-user>
  <!--<li class="nav-item">
    <button (click)="onToggleControlSidebar()" class="nav-link">
      <i class="fas fa-th-large"></i>
    </button>
  </li>-->
</ul>
